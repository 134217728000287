<template>
  <div>
    <h1 class="text-center">Generar Reportes</h1>

    <v-breadcrumbs :items="bread" large></v-breadcrumbs>

    <v-spacer></v-spacer>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <template>
              <v-col>
                <v-select
                  v-model="filtroFideicomiso"
                  :items="fideicomisos.map((el) => el.nombre)"
                  label="Fideicomiso"
                  @change="getOperatoria(filtroFideicomiso)"
                >
                  ></v-select
                >
              </v-col>
              <v-col>
                <v-select
                  v-model="filtroOperatoria"
                  :items="operatorias.map((el) => el.nombre)"
                  label="Operatoria"
                  @change="getparametros(filtroOperatoria)"
                ></v-select>
              </v-col>
              <v-divider></v-divider>
              <template>
                <v-container>
                  <v-row>
                    <v-col
                      cols="3"
                      v-for="param in listaParametros"
                      :key="param.id"
                    >
                      <v-checkbox
                        :label="param.nombre"
                        v-model="selectedParams"
                        :value="param.id"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <v-divider></v-divider>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaInicio"
                    label="Desde fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaFinal"
                    label="Hasta fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFinal"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="informe(0)" class="mr-3 ml-5 mb-2" color="info">
              <span v-if="!isLoading"> Ver informe </span>
              <v-progress-circular
                v-if="isLoading"
                :size="25"
                color="white"
                indeterminate
              ></v-progress-circular>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn @click="informe(1)" class="mr-3 mb-2" color="info">
              <span v-if="!isLoading"> Guardar Informe </span>
              <v-progress-circular
                v-if="isLoading"
                :size="25"
                color="white"
                indeterminate
              ></v-progress-circular>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn @click="informe(2)" class="mr-3 mb-2" color="success">
              <span v-if="!isLoading"> Guardar excel </span>
              <v-progress-circular
                v-if="isLoading"
                :size="25"
                color="white"
                indeterminate
              ></v-progress-circular>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" class="float-right mr-3" @click="goBack">
              Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </div>
</template>
<script>
import axios from 'axios';
import logo from '@/common/logo';
import { API_URL } from '@/common/config';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import xlsx from 'xlsx';
import { showSimpleMessage } from '@/common/util';

export default {
  data: () => ({
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
    informes: [],
    informesPDF: [],
    fideicomisosId: '',
    listaOperatorias: [],
    fideicomisos: [],
    operatorias: [],
    listaParametros: [],
    selectedParams: [],
    operatoria: '',
    menu1: false,
    menu2: false,
    fechaInicio: '',
    fechaFinal: '',
    filtroOperatoria: '',
    filtroFideicomiso: '',
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Dashboard',
        disabled: true,
        href: '/',
      },
    ],
    isLoading: false,
  }),
  methods: {
    goBack() {
      window.history.back();
    },
    getFideicomiso() {
      axios
        .get(`${API_URL}api/admin/unidadGestion`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.fideicomisos = response.data.fideicomisos;
        });
    },
    getOperatoria(fideicomisoFilt) {
      this.filtroNroTicket = '';
      const fideicomisos = this.fideicomisos.filter((el) => {
        if (el.nombre === `${fideicomisoFilt}`) return el.id;
      });
      this.fideicomisosId = fideicomisos[0].id ? fideicomisos[0].id : '';
      axios
        .get(
          `${API_URL}api/servicio/tipoServicioXfideicomiso/list/${this.fideicomisosId}`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          this.operatorias = response.data.listado;
        });
      this.resetFilters;
    },
    getparametros(operatoriaFilt) {
      this.listaParametros = [];
      this.selectedParams = [];
      const tipoServicio = this.operatorias.filter((el) => {
        if (el.nombre === `${operatoriaFilt}`) return el.id;
      });
      const tipoServicioId = tipoServicio[0].tipo_servicio_id
        ? tipoServicio[0].tipo_servicio_id
        : '';

      axios
        .get(
          `${API_URL}api/tipoParametrosOpreatoria/${tipoServicioId}/cliente`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          this.listaParametros = response.data.listaParametros;
          this.listaParametros = response.data.listaParametros.filter((item) =>
            item.clave.toLowerCase().includes('monto')
          );
        });
    },

    async getListaOperatorias() {
      const url = `${API_URL}api/servicio`;
      const { data } = await axios.get(url, this.header);
      this.listaOperatorias = data.lineaCreditos.reduce((acc, el) => {
        if (el.tipo_servicio_id.id === 20) {
          return acc.concat({ text: el.nombre, value: el.id });
        }
        return acc;
      }, []);
      this.listaOperatorias.unshift({ text: 'General', value: '' });
    },

    async getTipomoras() {
      const query = {
        method: 'GET',
        headers: {
          token: localStorage.getItem('token'),
        },
        url: `${API_URL}/api/tipomoras`,
        data: {
          operatoria,
          fideicomisosId: fideicomisosId ? fideicomisosId : 15,
          param,
        },
      };
      const { data } = await axios(query);
      this.solicitudes = data.resultados;
    },
    async informe(tipo) {
      if (!this.filtroOperatoria) {
        return showSimpleMessage(
          'Informe',
          'Debe seleccionar una operatoria',
          'info',
          2000
        );
      }
      this.isLoading = true;
      const operatoria = this.operatorias.find(
        (el) => el.nombre === this.filtroOperatoria
      );
      const desde = this.fechaInicio || '';
      const hasta = this.fechaFinal || '';
      const operatoriaId = operatoria.id;
      const query = {
        method: 'POST',
        headers: {
          token: localStorage.getItem('token'),
        },
        url: `${API_URL}api/reportes/operatoria`,
        data: {
          operatoriaId,
          desde,
          hasta,
          parametros: this.selectedParams,
        },
      };

      const columnsParams = [];
      try {
        const { data } = await axios(query);
        this.isLoading = false;

        if (data.status === 'success' && data.creditos.length > 0) {
          if (this.selectedParams.length > 0) {
            const seleccionados = this.listaParametros.filter((items) =>
              this.selectedParams.includes(items.id)
            );
            seleccionados.map((items) =>
              columnsParams.push({ title: items.nombre, dataKey: items.clave })
            );
          }
          const datos = data.creditos.map((legajos) => ({
            legajo: legajos.id,
            operatoria: legajos.lineaCredito_id.nombre,
            estadoCredito: legajos.estadoCredito.nombre,
            cuit_beneficiario: legajos.cliente.dni
              ? legajos.cliente.dni
              : legajos.cliente.cuit,
            nombre_beneficiario:
              legajos.cliente.apellido !== '' && legajos.cliente.nombre !== ''
                ? `${legajos.cliente.apellido} ${legajos.cliente.nombre}`
                : legajos.cliente.razonSocial,
            fecha_desembolso: legajos.fecha_desembolso,
            cbu: legajos.cliente.cbu,
            email: legajos.cliente.domicilioElectronico,
            celular: legajos.cliente.celular,
            estado: legajos.estado.estado,
            estadoManual: legajos.estado.estadoManual,
            tipoMora: legajos.estado.tipomora_id
              ? legajos.estado.tipomora_id.definicion
              : '',
            observacion: legajos.estado.observacion,
            diasMora: legajos.estado.diasatraso,
            saldoCapital: legajos.estado.saldocapital || 0,
            parametros: legajos.parametros.map((parametro) => ({
              id: parametro.id,
              valor: parametro.valor,
              clave: parametro.tipo_parametro_id.clave,
              nombre: parametro.tipo_parametro_id.nombre,
              pesos:
                parametro.tipo_parametro_id.options === 'float' ? true : false,
            })),
          }));

          this.informes = datos.map((legajos) => {
            const { parametros, saldoCapital, ...rest } = legajos;
            const saldoFormateado = parseFloat(saldoCapital);
            const parametrosObj = parametros.reduce((acc, param) => {
              const valorFormateado = param.pesos
                ? parseFloat(param.valor)
                : param.valor;
              acc[param.clave] = valorFormateado;
              return acc;
            }, {});
            return {
              ...rest,
              ...parametrosObj,
              saldoFormateado,
            };
          });
          this.informesPDF = datos.map((legajos) => {
            const { parametros, saldoCapital, ...rest } = legajos;
            const saldoFormateado = this.formatter.format(saldoCapital);
            const parametrosObj = parametros.reduce((acc, param) => {
              const valorFormateado = param.pesos
                ? this.formatter.format(param.valor)
                : param.valor;
              acc[param.clave] = valorFormateado;
              return acc;
            }, {});
            return {
              ...rest,
              ...parametrosObj,
              saldoFormateado,
            };
          });

          this.exportPdf(tipo);
        } else {
          this.informes = [];
          return showSimpleMessage(
            'Informe',
            data.message || 'No se encontraron datos para el informe',
            'error',
            2000
          );
        }
      } catch (err) {
        this.isLoading = false;
        return showSimpleMessage(
          'Informe',
          'Error al obtener los datos. Por favor, verifica tu conexión a internet e intenta nuevamente.',
          'error',
          2000
        );
      }
    },
    exportPdf(tipo) {
      if (!this.informes || this.informes.length === 0) {
        return 0;
      }
      const columnsParams = [];
      if (this.selectedParams.length > 0) {
        const seleccionados = this.listaParametros.filter((items) =>
          this.selectedParams.includes(items.id)
        );
        seleccionados.map((items) =>
          columnsParams.push({ title: items.nombre, dataKey: items.clave })
        );
      }

      const columnasPredeterminadas = [
        { title: 'Legajo', dataKey: 'legajo' },
        { title: 'DEUDOR', dataKey: 'nombre_beneficiario' },
        { title: 'CUIL/CUIT/DNI', dataKey: 'cuit_beneficiario' },
        { title: 'CBU', dataKey: 'cbu' },
        { title: 'Email', dataKey: 'email' },
        { title: 'Operatoria', dataKey: 'operatoria' },
        { title: 'Estado Crédito', dataKey: 'estadoCredito' },
        { title: 'Fecha Desembolso', dataKey: 'fecha_desembolso' },
        { title: 'Celular', dataKey: 'celular' },
        { title: 'Estado', dataKey: 'estado' },
        { title: 'Estado Manual', dataKey: 'estadoManual' },
        { title: 'Tipo Mora', dataKey: 'tipoMora' },
        { title: 'Observacion', dataKey: 'observacion' },
        { title: 'Dias Mora', dataKey: 'diasMora' },
        { title: 'Saldo Capital', dataKey: 'saldoFormateado' },
      ];
      const doc = new JsPDF('l', 'mm', [297, 410]);
      const columnsHands = columnasPredeterminadas.concat(columnsParams);

      const maxColumnWidths = columnsHands.map((column) => {
        const maxWidth = this.informesPDF.reduce((max, row) => {
          const cellValue = String(row[column.dataKey]);
          const width = doc.getStringUnitWidth(cellValue);
          return Math.max(max, width);
        }, 0);
        return maxWidth + 15;
      });

      const height = 50;
      const imgData = logo;
      doc.addImage(imgData, 'PNG', 10, 5, 48, 20);
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text(`${this.filtroOperatoria}`, 15, 30);
      doc.setFontSize(13);
      doc.text(`Fecha: ${moment().format('DD/MM/YYYY')}`, 150, 30);
      doc.setFontSize(15);
      doc.text(
        `Periodo desde: ${
          this.fechaInicio
            ? moment(this.fechaInicio, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : '-'
        } Hasta: ${moment(
          this.fechaFinal ? this.fechaFinal : new Date(),
          'YYYY-MM-DD'
        ).format('DD/MM/YYYY')}`,
        15,
        40
      );
      doc.autoTable(columnsHands, this.informesPDF, {
        startY: height,
        columnWidth: maxColumnWidths.length > 0 ? maxColumnWidths : 'wrap',
        columnStyles: columnsHands.map((column, index) => ({
          cellWidth:
            maxColumnWidths[index] - (column.dataKey === 'legajo' ? 1 : 0),
        })),
        styles: {
          fontSize: 8,
          cellPadding: 1,
          lineHeight: 0.4, // Aumenta el espacio entre líneas
          halign: 'center',
          valign: 'middle',
        },
        theme: 'grid',
        margin: { left: 10, right: 10, top: 10, bottom: 10 },
        headStyles: {
          fillColor: [0, 0, 255], // Color de fondo del encabezado
          textColor: [255, 255, 255], // Color del texto del encabezado
        },
      });
      if (tipo === 0) {
        window.open(doc.output('bloburl'), '_blank');
      }
      if (tipo === 1) {
        doc.save(
          `${this.filtroOperatoria}-${moment().format('DD/MM/YYYY')}.pdf`
        );
        this.fechaInicio = '';
        this.fechaFinal = '';
      }
      if (tipo === 2) {
        const informes =
          this.informes.length !== 0
            ? (this.informes = this.informes.map((informe) => {
                const nuevoObjeto = {};
                columnsHands.forEach((columna) => {
                  nuevoObjeto[columna.title] = informe[columna.dataKey];
                });
                return nuevoObjeto;
              }))
            : [
                {
                  'Nº Legajo': '',
                  Operatoria: '',
                  EstadoCrédito: '',
                  'CUIL/CUIT/DNI': '',
                  Beneficiario: '',
                  'Fecha Desembolso': '',
                  CBU: '',
                  Celular: '',
                  Estado: '',
                  'Estado Manual': '',
                  'Tipo Mora': '',
                  Observacion: '',
                  'Dias Mora': '',
                  'Saldo Capital': '',
                },
              ];
        const ws = xlsx.utils.json_to_sheet(informes);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'People');
        xlsx.writeFile(
          wb,
          `${this.filtroOperatoria}-${moment().format('DD/MM/YYYY')}.xlsx`
        );
      }
      this.limpiar();
    },
    limpiar() {
      this.isLoading = false;
      (this.fideicomisos = []),
        (this.operatorias = []),
        (this.operatoria = ''),
        (this.fechaInicio = ''),
        (this.fechaFinal = ''),
        (this.filtroOperatoria = ''),
        (this.filtroFideicomiso = ''),
        (this.listaParametros = []),
        (this.selectedParams = []),
        this.getFideicomiso();
    },
  },
  created() {
    this.getFideicomiso();
  },
};
</script>
<style>
.v-date-picker-table--date td {
  padding: 0px !important;
}

.custom-select {
  width: 150px; /* Ajusta el ancho según tus necesidades */
}
.custom-date-field {
  width: 150px; /* Ajusta el ancho según tus necesidades */
}
.my-1 {
  margin-bottom: 10px;
}
</style>
